import React, { useState } from 'react';
import { CommunicationPreferences } from './components/CommunicationPreference';
import { TitleBlock } from './components/TitlesBlock';
import "./style.scss";
import 'antd/dist/antd.css'
import { UseGetCategories } from './hooks/useGetCategories';
import { ShareAlertPreferences } from './components/ShareAlertPreferences';
import { PersonalDetail } from './components/PersonalDetail';
import { ButtonsBlock } from './components/ButtonsBlock';
import { UseGetSubscription } from './hooks/UseGetSubscription';
import translations from "../Common/translations.json";
import { settings } from "../../settings";
import { ModalNewSubscription } from './components/ModalNewSubscription';
import { useSelector } from 'react-redux';



export const MainPage = () => {

    const [isSubscribeConfirmed, setSubscribeConfirmed] = useState(false); 

    const [categoriesList, setCategoriesList] = useState([]);
    UseGetCategories(setCategoriesList);
    UseGetSubscription();

    const subscription = useSelector((state) => state.mainPage.subscription);
    const companyName = subscription.language.toUpperCase() === "ENGLISH" ? subscription.companyNames.ENGLISH : subscription.companyNames.ARABIC;

    const translation = translations[settings.lang];
    const [modalNewsSubscriptionIsOpen, setModalSudscribeIsOpen] = useState(false);

  const modalClose = () => {
      setModalSudscribeIsOpen(false);
    };


    return (
        <div className="main-wrapper">
            <div className="main-company-title-block">
                <span className="main-company-title">
                    {companyName}
                </span>
            </div>
            <div className="main-company-subscription-confirmed-block">
                <span className="main-company-subscription-confirmed-title">
                    {translation.modalStrings.subscriptionConfirmed}
                </span>
            </div>
           
            <div className="subscription-detail-wrapper">
                <div className="subscription-detail-block">
                    <span className="subscription-detail-title">
                        {translation.titles.subscriptionDetailTitle}
                    </span>
                </div>
                <div className="communication-preferences-block">
                    <TitleBlock title={translation.titles.communicationPreferencesTitle} />
                    <CommunicationPreferences
                        categoriesList={categoriesList}
                        translation={translation.communicationPreferences}
                        language={subscription.language} />
                </div>
                <div className="share-alert-preferences-block">
                    <TitleBlock title={translation.titles.shareAlertPreferencesTitle} />
                    <ShareAlertPreferences
                        translation={translation.shareAlertPreferences} 
                        subscription={subscription}/>
                </div>
                <div className="personal-detail-block">
                    <TitleBlock title={translation.titles.personalDetailTitle} />
                    <PersonalDetail
                        translation={translation.personalDetail} 
                        setModalSudscribeIsOpen={setModalSudscribeIsOpen}
                        emailSubscription={subscription.email} />
                    <ButtonsBlock
                        translation={translation.buttonsBlock} />
                </div>
                <ModalNewSubscription
        isOpenModel={modalNewsSubscriptionIsOpen}
        buttonCloseHandler={modalClose} />
            </div>
        </div>
    )
}