import { createBrowserHistory } from "history";
import queryString from "query-string";

const { lang = "en", listing } = queryString.parse(
  window.location.search
);

const history = createBrowserHistory()
const apiUrl = process.env.REACT_APP_API_URL;
const stylesUrl = process.env.REACT_APP_STYLES;

const currentLang = lang === "ar" ? "ar" : "en";

export const settings = {
  lang: currentLang,
  history,
  apiUrl,
  listing,
  stylesUrl,
}