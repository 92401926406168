import React, { useState } from "react";
import { CheckSubscriptionModal } from "../CheckSubscriptionModal";
import { ModalComponent } from "../ModalComponent";
import "./style.scss";

export const SubscribeBlock = ({
  fonts,
  translations,
  configuration,
  subscribeAction,
  modalSudscribeIsOpen,
  setModalSudscribeIsOpen
}) => {
  
  const [modalHaveSubscriptionIsOpen, setModalHaveSubscriptionIsOpen] = useState(false);
  const [modalHaveSubscriptionIsOpenSuccess, setModalHaveSubscriptionIsOpenSuccess] = useState(false);
  const doIhaveASubscriptionClick = () => {
    //setModalHaveSubscriptionIsOpen(true);
  };
  const subscribeClick = () => {
 //Todo   subscribeAction();
  };
  const modalClose = () => {
    setModalSudscribeIsOpen(false);
    setModalHaveSubscriptionIsOpen(false);
    setModalHaveSubscriptionIsOpenSuccess(false);
  };
  const submit = (email) => {
    setModalSudscribeIsOpen(false);
    setModalHaveSubscriptionIsOpen(false);
    setModalHaveSubscriptionIsOpenSuccess(true);
  };
  return (
    <div className="subscribeBlock-root">
      <button className="subscribeBlock-button" onClick={subscribeClick}> {translations.modalStrings.subscribe} </button>
      <span
        className="subscribeBlock-root-have-subscription"
       // style={{ color: fonts.body || "black" }}
        onClick={doIhaveASubscriptionClick}> {translations.modalStrings.doIhaveAsubscription} </span>
      <ModalComponent
        isOpenModel={modalSudscribeIsOpen}
        buttonHandler={modalClose}
        title={ translations.success }
        bodyTitle={ translations.youWereSuccessfully }
        body={`${translations.confirmationMessage} \n ${translations.pleaseAcceptTheRequest}`}
        buttonText={ translations.close } />
      <ModalComponent
        isOpenModel={modalHaveSubscriptionIsOpenSuccess}
        buttonHandler={modalClose}
        title={ translations.doIHaveASubscription }
        bodyTitle={ translations.yourPreferences }
        body={ translations.forYourSecurity }
        buttonText={ translations.close } />

      <CheckSubscriptionModal
        isOpenModel={modalHaveSubscriptionIsOpen}
        buttonCloseHandler={modalClose}
        buttonSubmitHandler={submit}
        title={ translations.doIHaveASubscription }
        bodyTitle={ translations.checkYourSubscription }     
        cancelText={ translations.close }
        submitText={ translations.submit }/> 
    </div>
  )
};
