import React, { useEffect, useState } from "react";
import { Checkbox, Input } from "antd";
import "antd/dist/antd.css";
import "./styles.scss";

export const AlertPreferenceCell = ({ titleText, descriptionText, isShowInput, isShowPercentsBlock, price, onChangeValue }) => {

  let isChecked = price ? true : false;

  const [checkboxState, setcheckboxState] = useState(isChecked);
  const [priceValue, setPriceValue] = useState(price);

  const onChangeCheckBox = (e) => {
    setcheckboxState(e.target.checked)

    if (checkboxState) {
      onChangeValue(null)
    } else{
      onChangeValue(priceValue)
    }
  };

  return (
    <div className="alert-cell-wrapper">
      <div className="alert-cell-checkbox-block">
      </div>
      <div className="alert-cell-left-block">
        <div className="alert-cell-title-text-wrapper">
          <Checkbox onChange={onChangeCheckBox} checked={checkboxState}
          />
          <span className="alert-cell-title-text" >{titleText}</span>
        </div>
        <div className="alert-cell-info-block">
          <div>
            <span className="alert-cell-desciption-text">{descriptionText}</span>
          </div>
        </div>
      </div>
      <div className="alert-cell-right-block">
        {isShowInput &&
          <div className="alert-cell-input-wrapper">
            <Input
            disabled={!checkboxState}
              value={priceValue ? priceValue : 0}
              onChange={(value) => setPriceValue(value.target.value)}
              className="alert-cell-input"
              onBlur={(e) => { onChangeValue(e.target.value) }}
              style={isShowPercentsBlock ? { 'width': '150px' } : { 'width': '190px', 'marginRight': '20px' }} />
            <span style={isShowPercentsBlock ? { 'visibility': 'visible' } : { 'display': 'none' }} className="alert-cell-span-percentage">%</span>
          </div>}
      </div>
    </div>
  );
};
