import React from "react";
import { Checkbox } from "antd";
import "antd/dist/antd.css";
import "./styles.scss";

export const AlertPreferenceDeilyClosingCell = ({ titleText, descriptionText, dailyClose, onChangeValue }) => {

  const onChangeCheckBox = (e) => {
      onChangeValue(!dailyClose)
  };
  
  return (
    <div className="alert-cell-wrapper">
      <div className="alert-cell-checkbox-block">
      </div>
      <div className="alert-cell-left-block">
        <div className="alert-cell-title-text-wrapper">
        <Checkbox onChange={onChangeCheckBox} checked={dailyClose}
        />
        <span className="alert-cell-title-text" >{titleText}</span>
        </div>
        <div className="alert-cell-info-block">
          <div>
            <span className="alert-cell-desciption-text">{descriptionText}</span>
          </div>
        </div>
      </div>
      <div className="alert-cell-right-block">
      </div>
    </div>
  );
};
