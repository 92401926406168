import React from "react";
import { useDispatch } from "react-redux";
import { UseDeleteUnsubscribe } from "../../hooks/useDeleteUnsubscribe";
import "./style.scss";
import { updateSubscriptionRequest } from "../../actions";

export const ButtonsBlock = ({
  translation
}) => { 

  const dispatch = useDispatch();

  const onCLickUpdate = () => {
      dispatch(updateSubscriptionRequest());
  }
  const onCLickUnsubscribe = () => {
    UseDeleteUnsubscribe();
  }
  return (
    <div className="buttons-block-root">
      <button className="buttons-block-left" onClick={onCLickUpdate}>
        {translation.update}
      </button>
      <button className="buttons-block-right" onClick={onCLickUnsubscribe}>
      {translation.unsubscribe}
      </button>
    </div>
  );
};
