import axios from "axios";
import { settings } from "../../../settings";

const getClient = (opts) => {
  const options = {
    baseURL: opts.baseUrl || null,
  };

  const client = axios.create(options);

  client.interceptors.request.use(
    (requestConfig) => {
      return requestConfig;
    },
    (requestError) => {}
  );

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      return Promise.reject(error);
    }
  );

  return client;
};

function updateConf(conf) {
  const { params = {}, ...rest } = conf;
  return {
    ...rest,
  
    params: {
      ...params,
    },
  };
}

function makeStylesRequest(type, url, conf = {}) {
  const newUrl = `${settings.stylesUrl}${url}`;

  return getClient({})
    [type](newUrl, updateConf(conf))
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function makeRequest(type, url, conf = {}) {
  const newUrl = `${settings.apiUrl}${url}`;

  return getClient({})
    [type](newUrl, updateConf(conf))
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function makeRequestWithData(type, url, data = {}, conf = {}) {
  const newUrl = `${settings.apiUrl}${url}`;

  return getClient({})
    [type](newUrl, data, updateConf(conf))
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export const http = {
  getStyles(url, conf = {}) {
    return makeStylesRequest("get", url, conf);
  },
  get(url, conf = {}) {
    return makeRequest("get", url, conf);
  },
  delete(url, conf = {}) {
    return makeRequest("delete", url, conf);
  },
  head(url, conf = {}) {
    return makeRequest("head", url, conf);
  },
  options(url, conf = {}) {
    return makeRequest("options", url, conf);
  },
  post(url, data = {}, conf = {}) {
    return makeRequestWithData("post", url, data, conf);
  },
  put(url, data = {}, conf = {}) {
    return makeRequestWithData("put", url, data, conf);
  },
  patch(url, data = {}, conf = {}) {
    return makeRequestWithData("patch", url, data, conf);
  },
};
