import './App.css';
import { AppRouter } from './modules/Router';

function App() {
  return (
    <div className="App">
          <AppRouter/>
    </div>
  );
}

export default App;
