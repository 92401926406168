import React from "react";
import { MainPage } from "../MainPage";
import { settings } from "../../settings";
import { Layout } from "antd";
import { Route, Router } from "react-router-dom";
import { UnsubscribePage } from "../UnsubscribePage";

export const AppRouter = () => { 
 
  // const getRoutes = () => {
   
  // };

  return (
    <Layout>
            <Router history={settings.history}>
                <Route path={["/"]} exact component={() => <MainPage/>} />
                <Route path={["/unsubscribe"]} component={() => <UnsubscribePage />} />
            </Router>
          </Layout>
  );
};
