import React, { useEffect, useState } from 'react';
import "./style.scss";
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { settings } from '../../../../settings';
import { Checkbox, Input, InputNumber } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { setCategories, setLanguage } from "../../actions";

export const CommunicationPreferences = ({
    categoriesList,
    translation,
    language
}) => {

    const dispatch = useDispatch();

   let lang = "";
   if(language === "ENGLISH"){
       lang = "English";
   }else if(language === "ARABIC"){
       lang = "Arabic"
   }else{
       lang = language;
   }

    const dispantch = useDispatch();
    const listCheched = useSelector((state) => state.mainPage.subscription.categories);

    const onChangeCheckbox = (e) => {
       
      if (e.target.checked) {
        const newCategories = [...listCheched, e.target.id];
        dispantch(setCategories(newCategories));
      } else {
        const newCategories = listCheched.filter((t) => t !== e.target.id);
        dispantch(setCategories(newCategories));
      }
    };
    
    const isCheched = (categoriName) => {
        if(!listCheched) 
            return false;

        const item = listCheched.find(f => f === categoriName);
        if(item) 
            return true

        return false;
    }
      
    function handleMenuClick(e) {
        dispatch( setLanguage(e.key) )
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="English" >
          {translation.english}
          </Menu.Item>
          <Menu.Item key="Arabic" >
          {translation.arabic}
          </Menu.Item>
        </Menu>
      );



    return(
        <div className="communication-preferences-wrapper"> 
            <div className="communication-preferences-msg-type-block">
                <div className="commun-pre-msg-bl">
                    <span className="commun-pre-title">
                        {translation.messageType}
                    </span>
                </div>
                <div className="commun-pre-check-box">
                    {categoriesList && categoriesList.map((item)=> {
                       return(
                        <div className="checkbox-block-item" key={item.category}>   
                            <Checkbox 
                                checked={isCheched(item.category)}
                                id={item.category}
                                onChange={onChangeCheckbox} />
                            <span 
                                className="checkbox-block-item-span">
                                {settings.lang === "en" ? item.values.ENGLISH : item.values.ARABIC}
                            </span>
                        </div>
                       ) 
                    })}
                 
                </div>
                <div className="commun-pre-lang-block">
                    <div className="commun-pre-lang-title">
                        <span className="commun-pre-lang-title-text">
                        {translation.language}
                        </span>
                    </div>
                    <div className="commun-pre-lang-button">
                        <Dropdown 
                            overlay={menu}>
                            <Button className="commun-pre-lang-dropdown-button">
                                {lang} <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    )
}