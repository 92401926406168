import React, { useEffect, useState } from "react";
import "./style.scss";

export const PersonalDetail = ({
  translation,
  setModalSudscribeIsOpen,
  emailSubscription
}) => {


  const [isValidEmail, setIsValidEmail] = useState(true);
  const [email, setEmail] = useState();
  const isDisableEmail = emailSubscription ? true : false;
  const onCLickText = () => {
    let state = checkValidation(email);
    setModalSudscribeIsOpen(true)
    if (state) {
      //todo
    }
  }

  function checkValidation(email) {
    if (!email) {
      setIsValidEmail(false)
      return false;
    }
    else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setIsValidEmail(false)
      return false;
    }
    else {
      setIsValidEmail(true);
      return true;
    }
  };


  
  return (
    <div className="personal-detail-wrapper">
      <div className="emailBlock-root">
        <div className="emailBlock-input-title">
          <span>{translation.email}</span>
        </div>
        <div className="emailBlock-input-block">
          <input
            value={emailSubscription ? emailSubscription : ""}
            disabled={isDisableEmail}
            onChange={(e) => setEmail(e.currentTarget.value)}
            onBlur={(e) => checkValidation(e.currentTarget.value)}
            className={isValidEmail ? "emailBlock-input" : "emailBlock-input-error"}
            type="email" />
        </div>
      </div>
      <div className="emailBlock-description">
        <span className="emailBlock-description-first">
        {translation.toUpdateYourEmail}
            </span>
        <span className="emailBlock-description-second" onClick={onCLickText}>
        {translation.subscribeAgainWithANewEmailAddress}
            </span>
      </div>
    </div>
    
  );
};
