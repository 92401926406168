import React, { useState } from "react";
import "./style.scss";
import ToolTip from 'react-portal-tooltip'
import { settings } from "../../../../../../settings";
export const CheckboxBlock = ({ translations, setCategories, setListing, categoriesList }) => {
  const onFocusInfo = () => {
    //<p data-tip="hello world">Tooltip</p>
  }
  const [listCheckbox, setListCheckbox] = useState([]);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const onChangeCheckbox = (e) => {
    if (e.target.checked) {
      const newCategories = [...listCheckbox, e.target.id];
      setListCheckbox(newCategories);
      setCategories(newCategories);
    } else {
      const newCategories = listCheckbox.filter(
        (t) => t !== e.target.id
      );
      setListCheckbox(newCategories);
      setCategories(newCategories);
    }
  };
  const onChangeAlertCheckbox = (e) => {
    if (e.target.checked) {
      setListing(true);
    } else {
      setListing(false);
    }
  };

  const style = {
    style: {
      transition: 'none',
    },
    arrowStyle: {
    }
  }

  return (
    <div className="checkbox-wrapper">
      <div className="checkbox-title">
        <span>
          {translations.modalStrings.selectTheTypesOfEmailMessages}
        </span>
      </div>
      <div className="checkbox-block">
        {categoriesList &&
          categoriesList.map((item) => {
            return (
              <div className="checkbox-block-item" key={item.category}>
                <input type="checkbox" id={item.category} onChange={onChangeCheckbox} />
                <span> {settings.lang === "en" ? item.values.ENGLISH : item.values.ARABIC}</span>
              </div>
            );
          })}
        <div className="checkbox-block-item">
          <input type="checkbox" onChange={onChangeAlertCheckbox} />
          <span> {translations.modalStrings.shareAlerts} </span>
          <div className="checkbox-block-image">
            <img id="icon_i"
               onMouseEnter={()=> setIsShowTooltip(true)} 
               onMouseLeave={()=> setIsShowTooltip(false)}
              className="icon" alt="save" src="./icons/info-circle.svg" onClick={onFocusInfo} />
           <ToolTip  className="checkbox-tooltip"
           style={style}
           tooltipTimeout={0}
           active={isShowTooltip} parent="#icon_i" position="right" arrow={"center"}>
           <div className="checkbox-block-tooltip-wrapper">
                <span className="checkbox-block-tooltip-share-alerts-title" > {translations.modalStrings.shareAlerts}:</span>
                <span className="checkbox-block-tooltip-span-with-dots"> &#8226; {translations.modalStrings.dailyClosingPrice} </span>
                <span className="checkbox-block-tooltip-span-with-dots">&#8226; {translations.modalStrings.priceVariation} </span>
                <span className="checkbox-block-tooltip-span-with-dots">&#8226; {translations.modalStrings.priceTarget} </span>
                <span className="checkbox-block-tooltip-span-with-dots">&#8226; {translations.modalStrings.stopLossPrice} </span>
                <span className="checkbox-block-tooltip-span-with-dots">&#8226; {translations.modalStrings.volumeTarget} </span>
                <span className="checkbox-block-tooltip-by-default-title" > {translations.modalStrings.byDefaultYouWill} </span>
                <span className="checkbox-block-tooltip-bottom-span"> {translations.youMayManageAll} </span>
              </div>
        </ToolTip>
          </div>
        </div>
      </div>
    </div>
  );
};
