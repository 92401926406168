import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { settings } from "../../../settings";
import { http } from "../../Common/api";
import { setSubscription } from "../actions";
import { subscriptionDefault } from "../DefaultValue/subscriptionDefault"

export const UseGetSubscription = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const getSubscription = async () => {
            const dataSubscription = await http
                .get(`subscription/${settings.listing}`)
                .then((response) => response.data)
                .catch((e) => {
                    console.log("Error: getSubscription");
                });

                if(dataSubscription){
                    dispatch(setSubscription(dataSubscription))
                }else{
                    dispatch(setSubscription(subscriptionDefault))
                }
           
            if(dataSubscription && dataSubscription.id){
                 await http
                .put(`subscription/${dataSubscription.id}/activate`)
                .then((response) => response.data)
                .catch((e) => {
                    console.log("Error: activate");
                });
            }
          

            // if(dataSubscription && dataSubscription.listingSubscriptions[0]){

            //     const id = dataSubscription.listingSubscriptions[0].id;
            //     const dataStyles = await http
            //         .getStyles(id)
            //         .then((response) => response.data)
            //         .catch((e) => {
            //             console.log("Error: dataStyles");
            //         });

            //     debugger;
            // }

        }
        if (settings.listing) {
        getSubscription();
    }
}, [dispatch]);
    
}