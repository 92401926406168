import React from 'react';
import { useSelector } from 'react-redux';
import "./style.scss";
import { settings } from "../../settings";
import translations from "../Common/translations.json";


export const UnsubscribePage = () => {
    const translation = translations[settings.lang];
    const subscription = useSelector((state) => state.mainPage.subscription);
    console.log(subscription);
    const companyName = subscription.language.toUpperCase() === "ENGLISH" ? subscription.companyNames.ENGLISH : subscription.companyNames.ARABIC;
    return (
        <div className="unsubscribepage-wrapper">
            <div className="unsubscribepage-company-title-block">
                <span className="unsubscribepage-company-title">
                    {companyName}       
                </span>
            </div>
            <div className="unsubscribepage-detail-wrapper">
                <div className="subscription-detail-block-top">
                    <span className="subscription-detail-title">
                        {translation.modalStrings.youWereSuccessfullyUnsubscribed}
                    </span>
                </div>
                <div className="subscription-detail-block-bottom">
                    <span className="subscription-detail-title">
                        {translation.modalStrings.ifYouWouldLikeToResubscribe}
                    </span>
                </div>
            </div>
        </div>
    )
}