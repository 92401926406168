import React from 'react';
import "./style.scss";

export const TitleBlock = ({title}) => {
    return(
        <div className="title-block-wrapper"> 
             <span className="title-block-text">
                {title}
             </span>
        </div>
    )
}