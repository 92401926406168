import { http } from "../../Common/api";
import { settings } from "../../../settings";

export const UseDeleteUnsubscribe = () => {      
        const deleteUnsubscribe = async () => {
            const data = await http
              .delete(`subscription/${settings.listing}`)
              .then((response) => response)   
              .catch((e) => {
                console.log("Error: deleteUnsubscribe");
              });   
              settings.history.push({ pathname: "/unsubscribe" });                   
          }
          if (settings.listing) {
            deleteUnsubscribe(); 
        } 
    
}