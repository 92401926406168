import { takeEvery, select } from "redux-saga/effects";
import {UPDATE_SUBSCRIPTION_REQUEST } from "./consts";
import { http } from "../Common/api/index";

export function* mainPageSaga() {
    yield takeEvery(UPDATE_SUBSCRIPTION_REQUEST, (payload) => updateSubscroptionRequest(payload));
}

function* updateSubscroptionRequest({payload}){
    const obj = yield select((state) => state.mainPage.subscription)
    
    try{
        yield http
        .put(`subscription`, obj)
        .then((response) => response.data)
      
    }catch(e){
        console.log('Error : ' + e);
    }

}

