import React from "react";
import "./style.scss";
import { AlertPreferenceCell } from "./components/AlertPreferenceCell";
import { AlertPreferenceDeilyClosingCell } from "./components/AlertPreferenceCell/deily-closing";
import { setListingSubscription, setPriceChange, setPriceTarget } from "../../actions";
import { useDispatch } from "react-redux";

export const ShareAlertPreferences = ({ translation, subscription }) => {
const dispatch = useDispatch();
  const listingSubscription = subscription.listingSubscriptions[0];

  const onChangeDailyClosing = (dailyClosing) => {
    const newListingSubscription = [{...listingSubscription, dailyClose : dailyClosing }]
    dispatch(setListingSubscription(newListingSubscription));
  }

  const onChangePriceVariation = (priceVariation) => {
    const newListingSubscription = [{...listingSubscription, priceChange : priceVariation }]
    dispatch(setListingSubscription(newListingSubscription));
  }

  const onPriceTarget = (priceTarget) => {
    const newListingSubscription = [{...listingSubscription, priceTarget : priceTarget }]
    dispatch(setListingSubscription(newListingSubscription));
  }

  const onChangeStopLoss = (stopLoss) => {
    const newListingSubscription = [{...listingSubscription, stopLoss : stopLoss }]
    dispatch(setListingSubscription(newListingSubscription));
  }

  const onChangeVolumeTarget = (volumeTarget) => {
    const newListingSubscription = [{...listingSubscription, volumeTarget : volumeTarget }]
    dispatch(setListingSubscription(newListingSubscription));
  }

  return (
    <div className="shared-alerts-preference-alert">
      <AlertPreferenceDeilyClosingCell
        titleText={translation.dailyClosingPriceTitle}
        descriptionText={translation.dailyClosingPrice}
        dailyClose={listingSubscription.dailyClose}
        onChangeValue = {onChangeDailyClosing}
        isShowInput={false}
      />
      <AlertPreferenceCell
        titleText={translation.priceVariationTitle}
        descriptionText={translation.priceVariation}
        isShowInput={true}
        price={listingSubscription.priceChange}
        onChangeValue = {onChangePriceVariation}
        isShowPercentsBlock={true}
      />
      <AlertPreferenceCell
        titleText={translation.priceTargetTitle}
        descriptionText={translation.priceTarget}
        price={listingSubscription.priceTarget}
        onChangeValue={onPriceTarget}
        isShowInput={true}
      />
      <AlertPreferenceCell
        titleText={translation.stopLossTitle}
        descriptionText={translation.stopLoss}
        price={listingSubscription.stopLoss}
        onChangeValue={onChangeStopLoss}
        isShowInput={true}
      />
      <AlertPreferenceCell
        titleText={translation.volumeTargetTitle}
        descriptionText={translation.volumeTarget}
        price={listingSubscription.volumeTarget}
        onChangeValue={onChangeVolumeTarget}
        isShowInput={true}
      />
    </div>
  );
};
